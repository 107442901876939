import styled from 'styled-components';

/**
 * Container for the graphic.
 */
const ContainerGraphic = styled.div`
  svg {
    max-width: 350px;

    width: 100%;
    height: auto;
  }

  text-align: center;
`;

export { ContainerGraphic };
