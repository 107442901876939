import React from 'react';

import {
  ContainerTwoColumn,
  ContainerGraphic,
  ContainerText,
  ContainerButtons,
  H1,
} from '_templates/LandingSection';
import colours from '_theming/colours';
import ECommerceIllustration from '_images/svg/illustrations/Ecommerce.svg';
import ExternalLinkButton from '_components/ExternalLinkButton';
import InternalLinkButton from '_components/InternalLinkButton';

/**
 * The "Keep More Of The Money You Make" section.
 *
 * @param {object} props Component props.
 * @param {string} props.flexDirection Direction of the flex content.
 * @returns {React.Component} Section.
 */
const KeepMoreOfTheMoneyYouMake = ({ flexDirection }) => (
  <ContainerTwoColumn flexDirection={flexDirection}>
    <ContainerGraphic>
      <ECommerceIllustration />
    </ContainerGraphic>

    <ContainerText>
      <H1>Keep more of the money you make</H1>

      <p>
        From subscriptions that automatically generate and send invoices to your customers, to
        creating one-off invoices in only a few clicks or easily accepting recurring payments,
        Pricematepay helps you bill customers on your terms and keep more of the money you make.
      </p>

      <ContainerButtons>
        <ExternalLinkButton
          href="https://gateway.pricematepay.com/signup"
          bgcolor={colours.redDamask}
          target="_blank"
        >
          Get Started Now
        </ExternalLinkButton>

        <InternalLinkButton to="/contact/" bgcolor={colours.linkWater}>
          Get a Quote
        </InternalLinkButton>
      </ContainerButtons>
    </ContainerText>
  </ContainerTwoColumn>
);

export default KeepMoreOfTheMoneyYouMake;
