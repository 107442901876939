import React from 'react';

import SubscriptionsIllustration from '_images/svg/illustrations/Subscriptions.svg';
import { ContainerTwoColumn, H2 } from '_templates/Sections';

import { ContainerGraphic } from './common/Section';

/**
 * The "Subscriptions" section.
 *
 * @param {object} props Component props.
 * @param {string} props.flexDirection Direction of the flex content.
 * @returns {React.Component} Section.
 */
const Subscriptions = ({ flexDirection }) => (
  <ContainerTwoColumn flexDirection={flexDirection}>
    <ContainerGraphic>
      <SubscriptionsIllustration />
    </ContainerGraphic>

    <div>
      <H2>Subscriptions</H2>

      <p>
        Easily create subscriptions with recurring invoices or automatic charges at any interval, so
        that you, and your customers, can focus on what’s really important: your products and
        services.
      </p>
    </div>
  </ContainerTwoColumn>
);

export default Subscriptions;
