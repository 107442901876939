import styled from 'styled-components';

/**
 * Table.
 */
const Table = styled.div`
  display: table;

  border-spacing: 25px;
`;

/**
 * Table Row.
 */
const Row = styled.div`
  display: table-row;
`;

/**
 * Table Cell.
 */
const Cell = styled.div`
  display: table-cell;

  vertical-align: middle;
  text-align: center;

  font-size: 2rem;
`;

export { Table, Row, Cell };
