import React from 'react';

import { ContainerTwoColumn, H2 } from '_templates/Sections';
import HostedInvoiceIllustration from '_images/svg/illustrations/HostedInvoice.svg';

import { ContainerGraphic } from './common/Section';

/**
 * The "Hosted Invoices" section.
 *
 * @param {object} props Component props.
 * @param {string} props.flexDirection Direction of the flex content.
 * @returns {React.Component} Section.
 */
const HostedInvoices = ({ flexDirection }) => (
  <ContainerTwoColumn flexDirection={flexDirection}>
    <ContainerGraphic>
      <HostedInvoiceIllustration />
    </ContainerGraphic>

    <div>
      <H2>Hosted Invoices</H2>

      <p>
        Create and send mobile-friendly invoices that your customers can pay from anywhere, all
        without additional costs to your bottom line.
      </p>
    </div>
  </ContainerTwoColumn>
);

export default HostedInvoices;
