import React from 'react';
import styled from 'styled-components';

import { ContainerOneColumn, H2 } from '_templates/Sections';
import CreateIllustration from '_images/svg/illustrations/Create.svg';
import GetPaidIllustration from '_images/svg/illustrations/GetPaid.svg';
import SendIllustration from '_images/svg/illustrations/Send.svg';
import useIsClient from '_hooks/useIsClient';
import useResponsive from '_hooks/useResponsive';

import { Table, Row, Cell } from './common/Table';

/**
 * Container for icons which have a square aspect ratio.
 */
const ContainerSquareIcon = styled.div`
  svg {
    width: 150px;
    height: auto;
  }
`;

/**
 * Container for icons which have a wide aspect ratio.
 */
const ContainerRectangleIcon = styled.div`
  svg {
    width: 250px;
    height: auto;
  }
`;

/**
 * Container for the SVG graphic.
 */
const ContainerGraphic = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

/**
 * "Steps" for the Desktop Layout.
 *
 * @returns {React.Component} "Steps" graphic.
 */
const StepsForDesktopLayout = () => (
  <Table>
    <Row>
      <Cell>
        <ContainerSquareIcon>
          <CreateIllustration />
        </ContainerSquareIcon>
      </Cell>
      <Cell>
        <ContainerRectangleIcon>
          <SendIllustration />
        </ContainerRectangleIcon>
      </Cell>
      <Cell>
        <ContainerSquareIcon>
          <GetPaidIllustration />
        </ContainerSquareIcon>
      </Cell>
    </Row>
    <Row>
      <Cell>1. Create</Cell>
      <Cell>2. Send</Cell>
      <Cell>3. Get Paid</Cell>
    </Row>
  </Table>
);

/**
 * "Steps" for the Mobile Layout.
 *
 * @returns {React.Component} "Steps" graphic.
 */
const StepsForMobileLayout = () => (
  <Table>
    <Row>
      <Cell>
        <ContainerSquareIcon>
          <CreateIllustration />
        </ContainerSquareIcon>
      </Cell>
    </Row>
    <Row>
      <Cell>1. Create</Cell>
    </Row>

    <Row>
      <Cell>
        <ContainerRectangleIcon>
          <SendIllustration />
        </ContainerRectangleIcon>
      </Cell>
    </Row>
    <Row>
      <Cell>2. Send</Cell>
    </Row>

    <Row>
      <Cell>
        <ContainerSquareIcon>
          <GetPaidIllustration />
        </ContainerSquareIcon>
      </Cell>
    </Row>
    <Row>
      <Cell>3. Get Paid</Cell>
    </Row>
  </Table>
);

/**
 * The "Hosted Invoices In Three Easy Steps" section.
 *
 * @returns {React.Component} Section.
 */
const HostedInvoicesInThreeEasySteps = () => {
  const { isMobile } = useResponsive();
  const isClient = useIsClient();

  return (
    <ContainerOneColumn>
      <H2>Hosted Invoices In Three Easy Steps</H2>

      {isClient ? (
        <ContainerGraphic>
          {isMobile ? <StepsForMobileLayout /> : <StepsForDesktopLayout />}
        </ContainerGraphic>
      ) : null}
    </ContainerOneColumn>
  );
};

export default HostedInvoicesInThreeEasySteps;
